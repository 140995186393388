<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmit())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="3">
            <b-form-group>
              <label>Nama Bucket<span class="text-danger mr5">*</span></label>
              <b-form-input v-model="row.mbf_name" @input="$parent.removeMultiSpace($event,'mbf_name')" placeholder="e.g Data Completion"
              @keyup="$parent.removeWildChar"
              @keydown="$parent.removeWildChar">
              </b-form-input>
              <VValidate 
                name="Nama Bucket" 
                v-model="row.mbf_name" 
                :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:3, max: 60, alpha_spaces:1}"
              />
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group>
              <label>PIC<span class="text-danger mr5">*</span></label>
              <v-select placeholder="Select PIC Level" v-model="row.mbf_pic_level_id" :options="$parent.mrPIC" label="text" :reduce="v=>v.value"></v-select>
              <VValidate 
                name="PIC" 
                v-model="row.mbf_pic_level_id" 
                :rules="{required:1}"
              />
            </b-form-group>
          </b-col>  

          <div class="w-100"></div>

          <b-col lg="3">
            <b-form-group>
              <label>Tipe Susunan<span class="text-danger mr5">*</span></label>
              <v-select placeholder="Select PIC Level" v-model="row.mbf_type" :options="mrTipeSusunan" label="text" :reduce="v=>v.value"></v-select>
              <VValidate 
                name="Tipe Susunan" 
                v-model="row.mbf_type" 
                :rules="{required:1}"
              />
            </b-form-group>
          </b-col> 
          <b-col v-if="!!total" lg="3">
            <b-form-group>
              <label>Posisi<span class="text-danger mr5">*</span></label>
              <select class="form-control" v-model="row.mbf_position" :disabled="row.mbf_type=='PARAREL'" >
                <option value="">Select Posisi</option>
                <option v-for="posisi in total+1" :key="posisi" :value="posisi">{{ posisi }}</option>
              </select>
              <VValidate 
                name="Posisi" 
                v-model="row.mbf_position" 
                :rules="{required:1}"
              />
            </b-form-group>
          </b-col> 
          <b-col v-if="row.mbf_type=='PARAREL' && !!total" lg="4">
            <b-form-group>
              <label>Pararel dengan<span class="text-danger mr5">*</span></label>
              <v-select placeholder="Pilih Bucket" v-model="row.mbf_pararel_with" :options="mrPararelWith" label="text" :reduce="v=>v.value"></v-select>
              <VValidate 
                name="Pararel dengan" 
                v-model="row.mbf_pararel_with" 
                :rules="{required:1}"
              />
            </b-form-group>
          </b-col> 

          <div class="w-100"></div>
          <b-col lg=3>
            <b-form-group v-if="row.mbf_position!=1 && !!total">
              <b-form-checkbox
                v-model="row.mbf_canbe_return"
                value="Y"
                unchecked-value="N"
              >
                Bisa Return?
              </b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="row.mbf_is_disbursement"
                value="Y"
                unchecked-value="N"
              >
                Pinjaman dicairkan di Bucket ini?
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col lg=3>
            <b-form-group>
              <b-form-checkbox
                v-model="row.mbf_canbe_drop"
                value="Y"
                unchecked-value="N"
              >
                Bisa Reject / Cancel?
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="row.mbf_canbe_drop=='Y'">
              <label>Reject/Cancel<span class="text-danger mr5">*</span></label>
              <b-form-radio-group v-model="row.mbf_drop_type">
                <b-form-radio value="REJECT">Reject</b-form-radio>
                <b-form-radio value="CANCEL">Cancel</b-form-radio>
              </b-form-radio-group>
              <VValidate 
                name="Reject/Cancel" 
                v-model="row.mbf_drop_type" 
                :rules="{required:1}"
              />
            </b-form-group>
          </b-col>

          <b-col lg=12>
            <b-form-group>
              <b-form-checkbox
                v-model="row.mbf_need_validation"
                value="Y"
                unchecked-value="N"
              >
                Aktifkan Cek Kelengkapan Field?
              </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="row.mbf_need_validation=='Y'">
              <label>Prasyarat Field Terpenuhi <span class="text-danger mr5">*</span></label>
              <div class="card card-body border border-primary-300" style="max-height:300px;overflow:auto">
                <ul class="list-unstyled">
                  <li v-for="(v1,k1) in $parent.mrFields" :key="k1" style="padding: 10px 0; border-bottom: 1px solid #eee;">
                    <h5 class="cursor-pointer" @click="v1.open=!v1.open">
                      <i :class="{'icon-arrow-right32':!v1.open, 'icon-arrow-down32':!!v1.open}"></i>
                      {{ v1.group_name }}
                    </h5>

                    <ul class="">
                      <li v-for="(v2,k2) in v1.sections" :key="k2">
                        <h6>{{ v2.section_name }}</h6>

                        <ul class="">
                          <li v-for="(v3,k3) in v2.fields" :key="k3" class="mb-2">
                            <b-form-checkbox
                              :checked="fieldIndex(v3.id)>-1"
                              @change="doField(v3)"
                            >
                              {{ v3.name }}
                            </b-form-checkbox>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <VValidate 
                name="Prasyarat Field Terpenuhi" 
                :value="( row.mbf_validation_fields||[] ).length?1:''"
                :rules="{required:1}"
              />
            </b-form-group>
            
            <b-row class="mb-3 mb-md-2 mt-2">
              <b-col lg=3>
                <b-form-group>
                  <label>Warna Label <span class="text-danger mr5">*</span></label>
                  <select class="form-control" v-model="row.mbf_label_color">
                    <option v-for="(v,k) in $parent.labelColor" :key="k" :value="v.value" :class="'bg-'+v.value">{{ v.text }}</option>
                  </select>
                  <VValidate 
                    name="Warna Label" 
                    :value="row.mbf_label_color"
                    :rules="{required:1}"
                  />
                </b-form-group>
              </b-col>
              <b-col lg=3>
                <b-form-group>
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3 mb-md-2 mt-2" v-if="row.mbf_type=='SERIAL'">
              <b-col lg=3>
                <b-form-group>
                  <b-form-checkbox
                    v-model="row.mbf_is_validate_amount"
                    value="Y"
                    unchecked-value="N"
                  >
                    Terapkan minimal nilai pinjaman pada bucket ini?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg=3 v-if="row.mbf_is_validate_amount=='Y'">
                <b-form-group>
                  <label>Nilai minimal pinjaman untuk ke bucket ini</label>
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </span>
                    <money  v-bind="money" v-model="row.mbf_validate_amount" class="form-control" placeholder="e.g 1.000.0000" />
                    <VValidate 
                      name="Nilai Validasi" 
                      :value="row.mbf_validate_amount"
                      :rules="{required:row.mbf_is_validate_amount=='Y'?true:false,min_value:1}"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group>
              <label>Catatan</label>
              <CKEditor 
                :value.sync="row.mbf_wording"
                :customToolbar="itemsToolbar"
              />
            </b-form-group>

            <b-form-group>
              <label>Dokumen Bucket</label>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th width="50">No.</th>
                    <th>Dokumen</th>
                    <th>Wajib ada?</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-for="(v,k) in row.mbf_document_bucket" :key="k">
                  <tr>
                    <td>{{ k+1 }}</td>
                    <td>
                      <v-select placeholder="Pilih Dokumen" v-model="v.document_id" :options="filterDocType(k)" label="text" :reduce="v=>parseInt(v.value)"></v-select>
                      <VValidate 
                        :name="'Dokumen ke-'+(k+1)" 
                        v-model="v.document_id" 
                        :rules="{required:true}"
                      />
                      <small v-if="v.document_id == 0" :id="'Dokumen bukcet ke'+(k+1)" class="label_error" style="">Dokumen Bucket ke {{k+1}} field is required</small>
                    </td>
                    <td>
                      <b-form-checkbox
                        v-model="v.is_required"
                        value="Y"
                        unchecked-value="N"
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <button type="button" class="btn btn-sm bg-danger-400" @click="row.mbf_document_bucket.splice(k,1)"><b><i class="icon-trash"></i></b></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">
                      <div class="text-center">
                        <button type="button" class="btn bg-blue-400 btn-labeled btn-labeled-left" @click="row.mbf_document_bucket.push({})"><b><i class="icon-plus-circle2"></i></b> Add Document</button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Submit <i class="icon-paperplane ml-2"></i></b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import CKEditor from '@/components/CKEditor'
import $ from 'jquery'
const _ = global._
import {Money} from 'v-money'

export default{
  components:{ CKEditor,Money },
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  data(){
    return {
      itemsToolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'insertTable','mediaEmbed',
        '|', 
        'outdent', 'indent',
        '|',
        'undo',
        'redo'
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked:false
      },
    }
  },
  computed:{
    total(){
      return this.$parent.total
    },
    mrTipeSusunan(){
      if(!this.total) return [
        {value:'SERIAL', text:'SERIAL'}
      ]
      else return this.Config.mr.BucketSusunanType
    },
    mrPararelWith(){
      if(!this.total) return []
      /* else if(this.row.typeBefore=="PARAREL"){
        return [
          {value:this.row.mbf_pararel_with, text: this.row.mbf_pararel_with=="BEFORE" ? "Bucket Sebelumnya" : "Bucket Setelahnya"}
        ] */
      else if(this.row.mbf_position > 1 && this.row.mbf_position < this.total+1){
        return [
          {value:'BEFORE',text:'Bucket Sebelumnya'},
          {value:'AFTER',text:'Bucket Setelahnya'},
          {value:'BEFOREAFTER',text:'Bucket Sebelumnya & Setelahnya'},
        ]
      }else if(this.row.mbf_position == this.total+1){
        return [
          {value:'BEFORE',text:'Bucket Sebelumnya'}
        ]
      }else{
        return [
          {value:'AFTER',text:'Bucket Setelahnya'}
        ]
      }
    }
  },
  methods:{
    filterDocType(key){
      let selected = _.filter(
        JSON.parse(JSON.stringify(this.row.mbf_document_bucket)),
        (v, k)=>{
          return k!=key && !!v.document_id
        }
      )||[]
      selected = _.map(selected, v=>parseInt(v.document_id))

      return _.filter(
        JSON.parse(JSON.stringify(this.$parent.mrDocType)),
        v=>{
          return selected.indexOf(parseInt(v.value))<=-1
        }
      )
    },
    fieldIndex(id){
      return _.map(this.row.mbf_validation_fields, 'id').indexOf(id)
    },
    doField(val){
      const i = this.fieldIndex(val.id)
      if(i>-1){
        this.row.mbf_validation_fields.splice(i,1)
      }else{
        this.row.mbf_validation_fields.push(JSON.parse(JSON.stringify(val)))
      }
    },
    doSubmit(){
      let inval = 0
      this.row.mbf_document_bucket = _.map(this.row.mbf_document_bucket, v=>{
        v.is_required = v.is_required||"N"
        // v.document_id = parseInt(v.document_id).toString()
        v.document_id = v.document_id?parseInt(v.document_id):0
        if(!v.document_id) inval = inval + 1
        // v.document_id = parseInt(v.document_id||null)
        return v
      })
      if(inval){
        return true
      }
      return this.$parent.doSubmit(
          "/do/BoMBucketFlow",
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              if(this.isAccountSetting){
                location.reload()
              }else{
                this.$router.push({name:this.$route.name})
              }
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                    console.log(i)
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  },
  watch:{
    'row.mbf_type'(n){
      if(n=="PARAREL"){
        this.$set(this.row, 'mbf_position', this.row.posisiBefore)
      }
    }
  }
}
</script>