<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col md="4">
                  <h5 class="card-title">Daftar {{ pageTitle }}</h5>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <v-select placeholder="Semua Tipe Susunan" @input="doFill" v-model="filter.type" :options="Config.mr.BucketSusunanType" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Nama Bucket</th>
                <th>PIC</th>
                <th>Tipe Susunan</th>
                <th>Status</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody v-if="!(dataList||[]).length">
              <tr>
                <th colspan="10">
                  <div class="text-center">
                    <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                    <div v-else-if="!dataList" class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                    <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
                  </div>
                </th>
              </tr>
            </tbody>
            <draggable v-if="(dataList||[]).length" v-model="data.data" @end="doSort" tag="tbody" draggable=".item" :disabled="!enableSort">
              <tr 
                v-for="(v) in dataList" 
                :key="v.mbf_id" 
                :class="{
                  'bg-warning-300':enableSort, 
                  'item':!!v.mbf_type
                }"
              >
                <td>
                  <span>{{v.mbf_position}}</span>
                  <!--
                  <span v-if="!v.mbf_type">0</span>
                  <span v-else>
                    {{(data.per_page*(data.current_page-1))+k+1-( data.current_page==1 ? 1 :0 )}}
                  </span>
                  -->
                </td>
                <td>
                  {{ v.mbf_name }}
                </td>
                <td>
                  {{ v.bul_name }}
                </td>
                <td>
                  <template v-if="v.mbf_type">
                    <b-badge :variant="( v.mbf_type=='SERIAL'?'info':'primary' )">{{ v.mbf_type }}</b-badge>
                    <div v-if="v.mbf_type=='PARAREL'" class="mt-2">
                      <small>
                        with <b-badge variant="danger">{{ v.mbf_pararel_with }}</b-badge> bucket
                      </small>
                    </div>
                  </template>
                  <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit')) || !v.mbf_type">-</span>
                </td>
                <td>
                  <template v-if="v.mbf_type">
                    <b-badge
                      v-if="v.mbf_is_active=='Y'"
                      variant="success"
                    >Aktif</b-badge>
                    <b-badge
                      v-else
                      variant="danger"
                    >Nonaktif</b-badge>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>
                  <template v-if="v.mbf_type">
                    <div class="list-icons">
                    <router-link class="list-icons-item"
                      v-if="moduleRole('edit')"
                      :to="{name: $route.name, params: {pageSlug: v[idKey]}}"
                      data-toggle="tooltip" data-placement="top" title="View"><i
                        class="icon-pencil5"></i></router-link>
                    <a href="javascript:;" class="list-icons-item"
                      v-if="moduleRole('delete')"
                      @click="doDelete(data.index, v)"
                      data-toggle="tooltip" data-placement="top" title="Delete"><i
                        class="icon-bin"></i></a>
                    <a href="javascript:;" class="list-icons-item"
                      v-if="moduleRole('changeStatus')"
                      @click="doChangeStatus(data.index, v)"
                      data-toggle="tooltip" data-placement="top" title="Change Status"><i
                        class="icon-cog6"></i></a>
                    </div>
                    <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <b-card-footer v-if="(dataList||[]).length&&data.total>10" class="pagination-custom">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
    </div>
  </div >
</template>

<script>
import draggable from 'vuedraggable'
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  components:{draggable,Form},
  extends: GlobalVue,
  data() {
    return {
      enableSort:false, // tunda
      idKey:'mbf_id',
      statusKey:'mbf_is_active',
      total:0,
      mrPIC:[],
      mrDocType:[],
      mrFields:[],
      labelColor:[],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doFill(){
      this.doFilter()
    },
    doSort(){
      console.log(this.data.data)
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>